var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "js-common-wrap js-realtime" }, [
    _vm._m(0),
    _c("div", { staticClass: "js-common-content js-realtime-content" }, [
      _c(
        "div",
        { staticClass: "js-realtime-table" },
        [
          _c(
            "div",
            { staticClass: "js-realtime-table-box" },
            [
              _c(
                "el-table",
                {
                  staticClass: "js-table",
                  attrs: {
                    data: _vm.tableData,
                    height: "100%",
                    "empty-text": "暂无数据",
                  },
                  on: { "row-click": _vm.handleRowClick },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "url", label: "实时图片" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("img", {
                              staticClass: "img",
                              attrs: { src: scope.row.url, alt: "" },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "上传人" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "dateTime", label: "上传时间" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "workName", label: "作业名称" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "steps", label: "步骤" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("common-pagination"),
        ],
        1
      ),
      _c("ul", { staticClass: "js-realtime-list" }, [
        _c("li", [
          _c("p", { staticClass: "date" }, [_vm._v(_vm._s(_vm.currentDate))]),
          _c("p", { staticClass: "time" }, [_vm._v(_vm._s(_vm.currentTime))]),
        ]),
        _vm._m(1),
        _vm._m(2),
        _vm._m(3),
        _vm._m(4),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "js-his-records-head" }, [
      _c("label", [_vm._v("5G+AR 智慧作业实时信息")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "icon-info" }, [
      _c("i", { staticClass: "icon icon-real" }),
      _c("div", { staticClass: "info-box" }, [
        _c("label", [_vm._v("当前在线")]),
        _c("p", [_c("i", { staticClass: "num" }, [_vm._v("0")]), _vm._v("人")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "icon-info" }, [
      _c("i", { staticClass: "icon icon-runing" }),
      _c("div", { staticClass: "info-box" }, [
        _c("label", [_vm._v("作业执行")]),
        _c("p", [_c("i", { staticClass: "num" }, [_vm._v("0")]), _vm._v("人")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "icon-info" }, [
      _c("i", { staticClass: "icon icon-num" }),
      _c("div", { staticClass: "info-box" }, [
        _c("label", [_vm._v("当前上报数量")]),
        _c("p", [_c("i", { staticClass: "num" }, [_vm._v("0")]), _vm._v("次")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("p", { staticClass: "title" }, [_vm._v("人员及作业情况")]),
      _c("div", { staticClass: "details" }, [
        _vm._v(
          " 人员及作业情况,人员及作业情况,人员及作业情况,人员及作业情况,人员及作业情况 人员及作业情况,人员及作业情况,人员及作业情况,人员及作业情况,人员及作业情况 人员及作业情况,人员及作业情况,人员及作业情况,人员及作业情况,人员及作业情况 人员及作业情况,人员及作业情况,人员及作业情况,人员及作业情况,人员及作业情况 人员及作业情况,人员及作业情况,人员及作业情况,人员及作业情况,人员及作业情况 人员及作业情况,人员及作业情况,人员及作业情况,人员及作业情况,人员及作业情况 人员及作业情况,人员及作业情况,人员及作业情况,人员及作业情况,人员及作业情况 人员及作业情况,人员及作业情况,人员及作业情况,人员及作业情况,人员及作业情况 人员及作业情况,人员及作业情况,人员及作业情况,人员及作业情况,人员及作业情况 "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }