<template>
  <div class="js-common-wrap js-realtime">
    <div class="js-his-records-head">
      <label>5G+AR 智慧作业实时信息</label>
    </div>
    <div class="js-common-content js-realtime-content">
      <div class="js-realtime-table">
        <div class="js-realtime-table-box">
          <el-table class="js-table" :data="tableData" height="100%" empty-text="暂无数据" @row-click="handleRowClick">
            <el-table-column prop="url" label="实时图片">
              <template slot-scope="scope">
                <img class="img" :src="scope.row.url" alt="">
              </template>
            </el-table-column>
            <el-table-column prop="name" label="上传人"></el-table-column>
            <el-table-column prop="dateTime" label="上传时间"></el-table-column>
            <el-table-column prop="workName" label="作业名称"></el-table-column>
            <el-table-column prop="steps" label="步骤"></el-table-column>
          </el-table>
        </div>
        <common-pagination></common-pagination>
      </div>
      <ul class="js-realtime-list">
        <li>
          <p class="date">{{ currentDate }}</p>
          <p class="time">{{ currentTime }}</p>
        </li>
        <li class="icon-info">
          <i class="icon icon-real"></i>
          <div class="info-box">
            <label>当前在线</label>
            <p><i class="num">0</i>人</p>
          </div>
        </li>
        <li class="icon-info">
          <i class="icon icon-runing"></i>
          <div class="info-box">
            <label>作业执行</label>
            <p><i class="num">0</i>人</p>
          </div>
        </li>
        <li class="icon-info">
          <i class="icon icon-num"></i>
          <div class="info-box">
            <label>当前上报数量</label>
            <p><i class="num">0</i>次</p>
          </div>
        </li>
        <li>
          <p class="title">人员及作业情况</p>
          <div class="details">
            人员及作业情况,人员及作业情况,人员及作业情况,人员及作业情况,人员及作业情况
            人员及作业情况,人员及作业情况,人员及作业情况,人员及作业情况,人员及作业情况
            人员及作业情况,人员及作业情况,人员及作业情况,人员及作业情况,人员及作业情况
            人员及作业情况,人员及作业情况,人员及作业情况,人员及作业情况,人员及作业情况
            人员及作业情况,人员及作业情况,人员及作业情况,人员及作业情况,人员及作业情况
            人员及作业情况,人员及作业情况,人员及作业情况,人员及作业情况,人员及作业情况
            人员及作业情况,人员及作业情况,人员及作业情况,人员及作业情况,人员及作业情况
            人员及作业情况,人员及作业情况,人员及作业情况,人员及作业情况,人员及作业情况
            人员及作业情况,人员及作业情况,人员及作业情况,人员及作业情况,人员及作业情况
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import CommonPagination from '@/components/CommonPagination'
export default {
  name: '',
  components: {
    CommonPagination
  },
  data() {
    return {
      tableData: [{
        url: 'https://ariseprod.llvision.com:45556/file/20220218160008090c540c2c7.jpg?bucketName=arise-msp-prod&storageDir=upload/car&storageFlag=2&token=58a39bbc651144de230bad25523a0834',
        name: '张三',
        dateTime: '2022-02-12 12:12:03',
        workName: '飞机检修',
        steps: '步骤',
      },{
        url: 'https://ariseprod.llvision.com:45556/file/20220218160008090c540c2c7.jpg?bucketName=arise-msp-prod&storageDir=upload/car&storageFlag=2&token=58a39bbc651144de230bad25523a0834',
        name: '张三',
        dateTime: '2022-02-12 12:12:03',
        workName: '飞机检修',
        steps: '步骤',
      },{
        url: 'https://ariseprod.llvision.com:45556/file/20220218160008090c540c2c7.jpg?bucketName=arise-msp-prod&storageDir=upload/car&storageFlag=2&token=58a39bbc651144de230bad25523a0834',
        name: '张三',
        dateTime: '2022-02-12 12:12:03',
        workName: '飞机检修',
        steps: '步骤',
      },{
        url: 'https://ariseprod.llvision.com:45556/file/20220218160008090c540c2c7.jpg?bucketName=arise-msp-prod&storageDir=upload/car&storageFlag=2&token=58a39bbc651144de230bad25523a0834',
        name: '张三',
        dateTime: '2022-02-12 12:12:03',
        workName: '飞机检修',
        steps: '步骤',
      },{
        url: 'https://ariseprod.llvision.com:45556/file/20220218160008090c540c2c7.jpg?bucketName=arise-msp-prod&storageDir=upload/car&storageFlag=2&token=58a39bbc651144de230bad25523a0834',
        name: '张三',
        dateTime: '2022-02-12 12:12:03',
        workName: '飞机检修',
        steps: '步骤',
      },{
        url: 'https://ariseprod.llvision.com:45556/file/20220218160008090c540c2c7.jpg?bucketName=arise-msp-prod&storageDir=upload/car&storageFlag=2&token=58a39bbc651144de230bad25523a0834',
        name: '张三',
        dateTime: '2022-02-12 12:12:03',
        workName: '飞机检修',
        steps: '步骤',
      },{
        url: 'https://ariseprod.llvision.com:45556/file/20220218160008090c540c2c7.jpg?bucketName=arise-msp-prod&storageDir=upload/car&storageFlag=2&token=58a39bbc651144de230bad25523a0834',
        name: '张三',
        dateTime: '2022-02-12 12:12:03',
        workName: '飞机检修',
        steps: '步骤',
      },{
        url: 'https://ariseprod.llvision.com:45556/file/20220218160008090c540c2c7.jpg?bucketName=arise-msp-prod&storageDir=upload/car&storageFlag=2&token=58a39bbc651144de230bad25523a0834',
        name: '张三',
        dateTime: '2022-02-12 12:12:03',
        workName: '飞机检修',
        steps: '步骤',
      },{
        url: 'https://ariseprod.llvision.com:45556/file/20220218160008090c540c2c7.jpg?bucketName=arise-msp-prod&storageDir=upload/car&storageFlag=2&token=58a39bbc651144de230bad25523a0834',
        name: '张三',
        dateTime: '2022-02-12 12:12:03',
        workName: '飞机检修',
        steps: '步骤',
      }],
      currentDate: '2022-12-01',
      currentTime: '12:12:03',
      timer: '',
    }
  },
  mounted() {
    this.currentDate = this.$Utils.getNowDate()
    this.timer = setInterval(() => {
      this.getCurrentTime()
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    getCurrentTime() {
      this.currentTime = this.$Utils.getNowTime()
    },
    handleRowClick(row, column, event){
      console.log(row)
      // this.$router.push({
      //   path: `/remoteCollaboration/historicalRecords/details/${parseInt(Math.random() * 190)}`,
      // })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/realTimeInformation.scss';
</style>
